<template>
  <div>
    <div class="xieyiin dborder clearfix">
      <div class="xieyibox">
        <h2>云策产品服务协议</h2>
        <div class="xieyineirong">
          在以下条款中，“用户”是指向注册成为本公司会员的个人或者单位。<br />

          用户同意此在线注册条款之效力如同用户亲自签字、盖章的书面条款一样，对用户具有法律约束力。<br />

          用户进一步同意，用户进入本公司会员注册程序即意味着用户同意了本注册条款的所有内容且只有用户完全同意所有服务条款并完成注册程序，才能成为本公司的正式用户。本注册条款自用户注册成功之日起在用户与本公司之间产生法律效力。<br />

          <h3>第一条 本公司服务简介</h3>

          本公司向其会员用户提供多层次、全方位的财税服务，本公司所提供的相关服务说明详见本公司网站的相关产品及服务介绍。<br />

          <h3>第二条 用户身份保证</h3>

          2-1
          用户承诺并保证自己是具有完全民事行为能力和完全民事权利能力的自然人、法人、实体和组织。用户在此保证所填写的用户信息是真实、准确、完整、及时的，并且没有任何引人误解或者虚假的陈述，且保证本公司可以通过用户所填写的联系方式与用户取得联系。<br />

          2-2
          用户应根据本公司对于相关服务的要求及时提供相应的身份证明等资料，否则本公司有权拒绝向该会员或用户提供相关服务。<br />

          2-3 用户承诺将及时更新其用户信息以维持该等信息的有效性。<br />

          2-4
          如果用户提供的资料或信息包含有不正确、不真实的信息，本公司保留取消用户会员资格并随时结束为该用户提供服务的权利。<br />

          2-5
          以代理人身份代理其他自然人或者单位进行注册用户必须向本公司提供代理人和被代理人的详细资料和信息及授权书面文件，未向本公司提供上述资料信息及文件的，本公司将视注册者为会员。<br />

          <h3>第三条 邮件通知</h3>

          用户充分理解本公司将通过网站留言等方式与注册会员保持联络及沟通，用户在此同意本公司通过网站留言等方式向其发送包括会员信息、本公司商品及服务信息在内的相关商业及非商业联络信息。<br />

          <h3>第四条 会员身份确认</h3>

          4-1
          用户注册成功后将得到一个用户名和密码，用户凭用户名和密码享受本公司向其会员用户提供的商品和服务。<br />

          4-2
          用户将对用户名和密码安全负全部责任，并且用户对以其用户名进行的所有活动和事件负全责。用户有权根据本公司规定的程序修改密码。<br />

          4-3
          非经本公司书面同意，用户名和密码不得擅自转让或者授权他人使用，否则用户应承担由此造成的一切后果。<br />

          4-4
          用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告本公司。
          <br />

          <h3>第五条 服务条款的修改和服务体系修订</h3>

          本公司有权在必要时修改服务条款，本公司服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的网络服务。如果用户继续享用本公司网络服务，则视为接受服务条款的变动。本公司保留随时修改其服务体系和价格而不需通知用户的权利，本公司修改其服务和价格体系之前用户就具体服务与本公司达成协议并已按照约定交纳费用的，本公司将按照已达成的协议执行至已交纳费用的服务期期满。<br />

          <h3>第六条 用户的权利和义务</h3>

          6-1
          用户有权利拥有自己在本公司的用户名和密码并有权利使用自己的用户名和密码随时登录本公司网站的会员专区。<br />

          6-2
          用户有权利享受本公司提供的互联网技术和信息服务，并有权利在接受本公司提供的服务时获得本公司的技术支持、咨询等服务，服务内容详见本公司相关产品介绍。<br />

          6-3
          用户保证不会利用技术或其他手段破坏及扰乱本公司网站以及本公司其他客户的网站。<br />

          6-4
          用户应尊重本公司及其他第三方的知识产权和其他合法权利，并保证在发生上述事件时尽力保护本公司及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失；本公司保留用户侵犯本公司知识产权时终止向该用户提供服务并不退还任何款项的权利。<br />

          6-5
          对由于用户向本公司提供的联络方式有误以及用户用于接受本公司邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，用户应自行承担责任，包括但不限于因用户未能及时收到本公司的相关通知而导致的后果和损失。<br />

          6-6
          用户保证其使用本公司服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用本公司提供的服务进行存储、发布、传播如下信息和内容：违反国家法律法规政策的任何内容（信息）；违反国家规定的政治宣传和/或新闻信息；涉及国家秘密和/或安全的信息；封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；防碍互联网运行安全的信息；侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容。用户同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等。用户承认本公司有权在用户违反上述时有权终止向用户提供服务并不予退还任何款项，因用户上述行为给本公司造成损失的，用户应予赔偿。<br />

          <h3>第七条 本公司的权利和义务</h3>

          7-1
          本公司应根据用户选择的服务以及交纳款项的情况向用户提供合格的网络技术和信息服务。<br />

          7-2
          本公司承诺对用户资料采取对外保密措施，不向第三方披露用户资料，不授权第三方使用用户资料，除非：<br />

          7-2-1
          依据本协议条款或者用户与本公司之间其他服务协议、合同、在线条款等规定可以提供；<br />

          7-2-2 依据法律法规的规定应当提供；<br />

          7-2-3 行政、司法等有权部门要求本公司提供；<br />

          7-2-4 用户同意本公司向第三方提供；<br />

          7-2-5 本公司解决举报事件、提起诉讼而提交的；<br />

          7-2-6
          本公司为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；<br />

          7-2-7
          本公司为向用户提供产品、服务、信息而向第三方提供的，包括本公司通过第三方的技术及服务向用户提供产品、服务、信息的情况。<br />

          7-3 本公司有权使用用户资料。<br />

          7-4
          本公司有权利对用户进行审查并决定是否接受用户成为本公司会员或是否与用户进行某一交易。<br />

          7-5
          本公司保留在用户违反国家、地方法律法规规定或违反本在线注册条款的情况下终止为用户提供服务并终止用户帐号的权利，并且在任何情况下，本公司对任何间接、偶然、特殊及继起的损害不负责任。<br />

          <h3>第八条 服务的终止</h3>

          用户有权随时申请终止其会员资格或其在本公司申请的一项或多项服务，但已交纳款项不得要求退还。<br />

          <h3>第九条 争议解决及法律适用</h3>

          9-1
          因本服务条款有关的一切争议，双方当事人应通过友好协商方式解决。如果协商未成，双方同意向本公司主要经营地的人民法院起诉。<br />

          9-2
          本注册条款的效力、解释、履行和争议的解决均适用中华人民共和国法律法规和计算机、互联网行业的规范。<br />

          <h3>第十条 不可抗力</h3>

          10-1
          因不可抗力或者其他意外事件，使得本条款履行不可能、不必要或者无意义的，遭受不可抗力、意外事件的一方不承担责任。<br />

          10-2
          不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。<br />

          10-3
          用户同意鉴于互联网的特殊性，黑客攻击、互联网连通中断或者系统故障等属于不可抗力，由此给用户或者第三方造成的损失不应由本公司承担。<br />

          <h3>第十一条 附则</h3>

          11-1
          本注册条款中有关条款若被权威机构认定为无效，不应影响其他条款的效力，也不影响本服务条款的解释、违约责任及争议解决的有关约定的效力。<br />

          11-2
          一方变更通知、通讯地址或其他联系方式，应自变更之日起及时将变更后的地址、联系方式通知另一方，否则变更方应对此造成的一切后果承担责任。用户同意，本公司的有关通知只需在本公司有关网页上发布即视为送达用户。<br />

          11-3
          因本公司上市、被收购、与第三方合并、名称变更等事由，用户同意本公司可以将其权利和/或义务转让给相应的本公司权利/义务的承受者。<br />

          用户在此再次保证已经完全阅读并理解了上述会员注册条款并自愿正式进入本公司会员在线注册程序，接受上述所有条款的约束。
        </div>
        <a href="javascript:void(0)" @click="closePage" class="close"
          >关闭</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
      closePage(){
        window.close();
      }
  },
};
</script>
<style scoped lang="less">
.xieyiin {
  width: 700px;
  margin: 0 auto;
  padding: 0 40px;
}
.dborder {
  border: 1px solid #d9d9d9;
}
.clearfix {
  *zoom: 1;
}
.clearfix:after {
    clear: both;
}
.clearfix:before, .clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}
.xieyineirong {
  width: 700px;
  margin: 30px auto;
  font-size: 15px;
  color: #666;
  line-height: 25px;
}
.xieyibox {
  h2 {
    font-size: 30px;
    padding: 30px 0;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
  }
  h3 {
    font-size: 17px;
    text-align: left;
    padding: 20px 0;
    color: #333;
  }
}
a.close {
    display: block;
    text-align: center;
    color: #000;
    padding: 30px 0;
    border-top: 1px solid #d9d9d9;
}
a:link{
    color: #666;
    text-decoration: none;
    font-size: 12px;
    font-family: '微软雅黑','Microsoft Yahei','5FAE8F6F96C59ED1';
}
</style>